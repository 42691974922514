.button.button-danger,
button.button-danger,
input[type="submit"].button-danger,
input[type="reset"].button-danger,
input[type="button"].button-danger {

	color: #FFF;
  	background-color: #D65151;
  	border-color: #D65151; 
}

.button.button-danger:hover,
button.button-danger:hover,
input[type="submit"].button-danger:hover,
input[type="reset"].button-danger:hover,
input[type="button"].button-danger:hover,
.button.button-danger:focus,
button.button-danger:focus,
input[type="submit"].button-danger:focus,
input[type="reset"].button-danger:focus,
input[type="button"].button-danger:focus {
  	
  	color: #FFF;
  	background-color: #CC1E1E;
  	border-color: #CC1E1E;
}

.button-success,
.button-error,
.button-warning,
.button-secondary {
    color: white;
}

.button-success {
    
    background: rgb(28, 184, 65); /* this is a green */
    border-color: rgb(28, 184, 65);
}

.button-error {
    
    background: rgb(202, 60, 60); /* this is a maroon */
    border-color: rgb(202, 60, 60);
}

.button-warning {
    
    background: rgb(223, 117, 20); /* this is an orange */
    border-color: rgb(223, 117, 20);
}

.button-secondary {
    
    background: rgb(66, 184, 221); /* this is a light blue */
    border-color: rgb(66, 184, 221); /* this is a light blue */
}

.button-success:focus,
.button-success:hover {

	color: white;
	background: rgb(30, 184, 65);
	border-color: rgb(30, 184, 65);
}

.button-error:focus,
.button-error:hover {

	color: white;
	background: rgb(204, 60, 60); /* this is a maroon */
    border-color: rgb(204, 60, 60);
}

.button-warning:focus,
.button-warning:hover {

	color: white;
	background: rgb(225, 117, 20); /* this is an orange */
    border-color: rgb(225, 117, 20);
}

.button-secondary:focus,
.button-secondary:hover {

	color: white;
	background: rgb(68, 184, 221); /* this is a light blue */
    border-color: rgb(68, 184, 221); /* this is a light blue */
}
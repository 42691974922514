/* 	Makes an image responsive */
.image-responsive {
	
	width: 100%;
	height: auto;
}

/*#MainAdminLoginContainer {

	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}
*/

.instagram-image {

	cursor: pointer;
}
.middle-of-page {

	margin-top: 25%;
}

.input-full-column-width {

	width: 100%;
}

.middle-of-page-45 {

	margin-top: 45%;
}

.header-logo {

	height: 5em;
	width: auto;
}

.small-gallery-image {

	height: 10em;
	width: auto;
}

.view-more-instagram-link {

	text-decoration: none;
}

.view-more-instagram-link:hover {

	text-decoration: underline;
}

.padding-right-image {

	padding-right: 2em;
}

.MainNav {

	position: sticky;
    left: 0;
    top: 0;
    background-color: white;
  	z-index: 1000000;
}

.date-label {

	padding: 5px;
	background-color: #005482;
	color: white;
	border-radius: 5px;
}

.divider {

	width: 5px;
	height: auto;
	display: inline-block;
}

.divider2 {

	width: 1.5em;
	height: auto;
	display: inline-block;
}

.danger-color {

	color: #D65151;
}

.danger-color:hover {

	color: #CC1E1E;
}

.alert-danger {

	color: #db0d0d;
	background-color: #ffe8e8;
	border: 0.1px solid #db0d0d;
	padding: 5px;
}

.login-background {

	background-color: #F3F3F3;
}

.hide {

	display: none;
}

.show {

	display: inline;
}

.fixed-height {

	height: 20em;
	overflow: hidden;
}

.header-image-responsive {

	width: auto;
	height: 100px;
}

.flexbox-display {

	display: inline;
}

.margin-top-25 {

	margin-top: 25px;
}

.no-padding {

	padding: 0 !important;
	margin: 0 !important;
}

a#NavLink {

	color: #336FC3;
	text-decoration: none;
	padding-right: 3em;
	cursor: pointer;
	/*color: #B04F4B;*/
}

a#NavLink.active {

	color: #222222;
}

a#NavLink.active:before {

	content: "> ";
}

a#NavLink:hover {

	color: #73c3ec;
	cursor: pointer;
}

.breadcrumb-link {

	color: #336FC3;
	padding-right: 20px;
	cursor: pointer;
}

.breadcrumb-link:hover {

	color: #73c3ec;
}

.blog-post-link {

	color: #40B1D6;
	padding-right: 20px;
	cursor: pointer;
}

.padding-right {

	margin-right: 2.5em;
}

.blog-post-link:hover {

	color: #222222;
}

.is-active {

	color: #222222;
}

.is-active:before {

	content: "> ";
}

.success-green {

	color: #16BB8F;
}

.button-success {

	border: 0;
	background-color: #16BB8F;
	color: white;
}

.success-green-bg {

	background-color: #16BB8F;
}

.image-holder {
  background-size: cover;
  background-position: center center;
  height: 20em;
  width: 100%;
}

.instagram-holder {

	/*height: 20em;*/
	width: 100%;
}

.facebook-like-plugin {

	border: none;
	overflow: hidden;
}

.italic-text {

	font-style: italic;
	float: right;
}

.overflow-none {

	overflow: none;
}

.twitter-holder {

	overflow: none;
	height: 30em;
	width: 100%;
}

.location-iframe {

	border: 0; 
	width: 100%; 
	height: 30em;
}

.copyright-footer-text {

	color: grey;
}

.outline-test {

	border: 1px solid black;
}

.center-align {

	text-align: center;
	margin: 0 auto;
}

.right-align {

	text-align: right;
	float: right;
}

.left-align {

	text-align: left;
	float: left;
}

.bold-text {

	font-weight: bold;
}

.twitter-iframe {

	position: static; visibility: visible; display: inline-block; width: 100%; height: 450px; padding: 0px; border: none; max-width: 100%; min-width: 180px; margin-top: 0px; margin-bottom: 0px; min-height: 200px;
}

.media-video {

	height: 40em;
}

.media-video-link {

	margin-right: 2em;
}

.capitilize-text {

	text-transform: capitalize !important;
}

.social-div {

	/*background-color: #5e5e5e;*/
	width: 100%;
	height: 100%;
	padding-top: 1em;
	padding-bottom: 1em;
	font-size: 2em;
}

#image-gallery {

	border: 1px dotted #c4c4c4;
	padding: 2em;
	/*border-radius: 30em;*/
	/*background-color: #f9f9f9;*/
}

#main-image-1 {

	background-image: url('../images/PaulOld.jpg');
}

#main-image-2 {

	background-image: url('../images/Dojang.jpg');
}

.italic-text {

	text-align: center;
	font-style: italic;
}

.homepage-quote {

	background-color: #f9f9f9;
	padding: 3em;
	border-left: 1em solid grey;
}

.quote-text {

	font-size: 1.5em;
}

.social-image {

	/*padding-right: 0.50em;*/
	font-size: 3em;
	cursor: pointer;
	color: grey;
}

.social-image:hover {

	color: #005482;
}

.about-div {

	height: 100%;
}

.about-image {

	/*height: 13em;*/
	/*width: auto;*/
}

.light-blue-text {

	color: #40B1D6;
}

.float-left {

	float: left;
}

.float-right {

	float: right;
}

#MainOverlay {

	text-align: center;
	background-color: white !important;
	z-index: 99999;
	position:absolute;
	top: 0;
	left: 0;
    height: 100%;
    width: 100%;
    display: none;
}

#MainLogoutOverlay {

	margin: auto;
}

#OverlayContent {

	width: 50%;
	height: 40%;
	overflow: auto;
	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
}


/*	RICHTEXT A LINKS */
.richText-toolbar a {

	color: black;
}

.richText-editor div {

	font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*	BLOG POSTS */
.blog-post-preview-thumbnail {

	border: 2px solid #E6E7EB;
}

.blog-post-preview-title {

	font-size: 2em;
}

.blog-post-preview-date-posted {

	font-size: 1em;
	font-weight: bold;
}

.blog-post-preview-divider {

	margin-bottom: 10px;
}

.blog-post-preview-stats {

	
}







/*
*	SCREEN SPECIFIC
*
*/

/*	This is for the table elements */
/*	Hide a few headers and related table columns on the mobile devices */
@media (max-width: 700px) {

	.big-screens-only {

		display: none;
	}
}

/* Larger than mobile */
@media (min-width: 400px) {

	.social-image {

		font-size: 2em;
	}
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {

	.social-image {

		font-size: 2em;
	}
}

/* Larger than tablet */
@media (min-width: 750px) {

}

/* Larger than desktop */
@media (min-width: 1000px) {

	.social-image {

		font-size: 3em;
	}
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {

	.social-image {

		font-size: 3em;
	}
}

@media (min-width: 700px) {

	.small-screen-only {

		display: none;
	}
}